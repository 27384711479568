import { useState } from "react";

const API_URL = process.env.GATSBY_DIRECTUS_API_URL;
const API_AUTH_TOKEN = process.env.GATSBY_CONTACT_FORM_API_AUTH_TOKEN;

type FormValue = {
  names: string;
  email: string;
  text: string;
};

type ApiResponse = FormValue & {
  date_created: string;
  date_updated: string | null;
  id: number;
};

const postContactForm = async (
  formValue: FormValue
): Promise<ApiResponse | null> => {
  const path = new URL("items/contact_form", API_URL);

  const body = JSON.stringify(formValue);

  let response: Response | null = null;

  try {
    response = await fetch(path.toString(), {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${API_AUTH_TOKEN}`,
        "Content-Type": "application/json",
      },
      body,
    });
    return (await response.json()) as ApiResponse;
  } catch {}

  return null;
};

const EMPTY_FORM_VALUE: FormValue = {
  names: "",
  email: "",
  text: "",
};

function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

type ValidationResult = {
  _error?: string;
  names?: string;
  email?: string;
  text?: string;
};

const validateForm = ({ email, names, text }: FormValue): ValidationResult => {
  if (!names || !email || !text) {
    return { _error: "należy uzupełnić wszystkie pola" };
  }

  if (names.length < 3) {
    return { names: "wpisz co najmniej 3 znaki" };
  }

  if (!validateEmail(email)) {
    return { email: "nieprawidłowy email" };
  }

  if (text.length < 20) {
    return { text: "wpisz co najmniej 20 znaków" };
  }

  return {};
};

export const useContactForm = () => {
  const [formValue, setFormValue] = useState(EMPTY_FORM_VALUE);

  const handleFormSubmit = async () => {
    await postContactForm(formValue);
    setFormValue(EMPTY_FORM_VALUE);
  };

  const validationErrors = validateForm(formValue);
  const isValid = Object.keys(validationErrors).length === 0;

  return {
    formValue,
    validationErrors,
    isValid,
    setFormValue,
    handleFormSubmit,
  };
};
