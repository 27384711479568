import React, { useContext } from "react";

import clsx from "clsx";

import { ThemeContext } from "../context/ThemeContext";
import { useContactForm } from "../hook/useContactForm";
import useTheme from "../hook/useTheme";

const inputStyle = "w-full p-2 my-2 rounded-md text-textPrimary-700 text-base ";

const labelStyle = "w-full text-sm font-medium text-white";

const ContactForm = () => {
  const { isHeightContrastEnable } = useContext(ThemeContext);
  const theme = useTheme(4);

  const {
    formValue,
    validationErrors,
    isValid,
    setFormValue,
    handleFormSubmit,
  } = useContactForm();

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();

        await handleFormSubmit();
      }}
      className="flex flex-col items-center justify-center space-y-4 text-textPrimary-700"
    >
      <label className={labelStyle}>
        Imię i nazwisko *
        <input
          className={clsx(
            inputStyle,
            !isHeightContrastEnable && "border-0 outline-none",
            isHeightContrastEnable && theme.focusWithin
          )}
          title="Wpisz swoje imię i nazwisko"
          type="text"
          placeholder="Imię i nazwisko"
          value={formValue.names}
          autoComplete="name"
          onChange={(e) => {
            setFormValue((prevState) => ({
              ...prevState,
              names: e.target.value,
            }));
          }}
        />
        <span className="text-red-500">{validationErrors?.names}</span>
      </label>
      <label className={labelStyle}>
        E-mail *
        <input
          className={clsx(
            inputStyle,
            !isHeightContrastEnable && "border-0 outline-none",
            isHeightContrastEnable && theme.focusWithin
          )}
          title="Wpisz swój adres e-mail"
          type="email"
          placeholder="Adres e-mail"
          value={formValue.email}
          autoComplete="email"
          onChange={(e) => {
            setFormValue((prevState) => ({
              ...prevState,
              email: e.target.value,
            }));
          }}
        />
        <span className="text-red-500">{validationErrors?.email}</span>
      </label>
      <label className={labelStyle}>
        Wiadomość *
        <textarea
          className={clsx(
            inputStyle,
            !isHeightContrastEnable && "border-0 outline-none",
            isHeightContrastEnable && theme.focusWithin
          )}
          title="Wpisz swoją wiadomość"
          placeholder="Wpisz wiadomość"
          rows={10}
          value={formValue.text}
          onChange={(e) => {
            setFormValue((prevState) => ({
              ...prevState,
              text: e.target.value,
            }));
          }}
        />
        <span className="text-red-500">{validationErrors?.text}</span>
      </label>
      <button
        disabled={!isValid}
        className={clsx(
          "ml-auto inline-block w-40 cursor-pointer rounded-md border-2 p-2",
          theme.bgStyle,
          !isHeightContrastEnable && theme.bgHover,
          isValid ? "border-white text-white" : "border-gray-600 text-gray-600"
        )}
      >
        Wyślij
      </button>
    </form>
  );
};

export default ContactForm;
