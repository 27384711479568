import React from "react";

import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import { faClock, faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faMapPin, faMobileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ContactForm from "../components/ContactForm";
import Headline from "../components/Headline";
import Layout from "../components/Layout";
import SectionContainer from "../components/SectionContainer";
import { SEO } from "../components/SEO";
import useTheme from "../hook/useTheme";
import { getHeroSectionPreviewImageSrc } from "../shared/utils/image";

const Contact = ({ data }) => {
  const theme = useTheme(4);

  const contact = data?.directus?.contact_page;

  return (
    <Layout theme={theme} pageTitle="Kontakt">
      <SEO
        urlPath="/kontakt/"
        imagePath={getHeroSectionPreviewImageSrc(data)}
        description={`Kontakt z nami: e-mail ${contact.email}, tel. ${contact.mobile}.\nMiejskie Schronisko dla Zwierząt w Tychach, ul. Przejazdowa 101, 43-100 Tychy.`}
      />
      <div className="container mx-auto my-10 min-h-screen px-10 text-white">
        <SectionContainer className="grid grid-cols-12 justify-center gap-8">
          {/*Map + Form*/}
          <div className="order-2 col-span-12 sm:col-span-10 sm:col-start-2 lg:col-span-7 lg:col-start-auto xl:col-span-8">
            <div>
              <div className="mb-3 flex items-center justify-start">
                <FontAwesomeIcon icon={faMapPin} className="mr-3 text-4xl" />
                <Headline
                  level={2}
                  title="Jak do nas dojechać"
                  bgClassName=""
                  paddingClassName=""
                />
              </div>

              <ul className="py-3">
                <li>Miejskie Schronisko dla Zwierząt w Tychach</li>
                <li>
                  <a
                    target="blank"
                    href="https://goo.gl/maps/BQqCNo4W3kPuNqL19"
                  >
                    ul. Przejazdowa 101
                  </a>
                </li>

                <li>43-100 Tychy</li>
              </ul>
              <div className="h-auto w-full">
                <a
                  className="cursor-pointer"
                  target="blank"
                  href="https://goo.gl/maps/BQqCNo4W3kPuNqL19"
                  title="Obraz mapy – lokalizacja Schroniska"
                >
                  <StaticImage
                    alt="google_map_image"
                    src="../images/Assets/GOOGLE_MAPS.png"
                    className="rounded-sm"
                  />
                </a>
              </div>
            </div>
            <div className="mt-8 max-w-xl">
              <div className="mb-3 flex w-full items-center justify-start text-white">
                <FontAwesomeIcon icon={faEnvelope} className="mr-3 text-4xl" />
                <Headline
                  title="Wyślij wiadomość"
                  level={2}
                  paddingClassName=""
                  bgClassName=""
                />
              </div>
              <ContactForm />
            </div>
          </div>
          {/*Contact*/}
          <div className="order-1 col-span-12 sm:col-start-2 lg:order-2 lg:col-span-5 lg:col-start-auto xl:col-span-4">
            <div>
              <div className="flex items-center justify-start">
                <FontAwesomeIcon icon={faMobileAlt} className="mr-3 text-4xl" />
                <Headline
                  level={2}
                  title="Kontakt"
                  bgClassName=""
                  paddingClassName=""
                />
              </div>

              <ul className="py-3">
                <li>
                  <a href={`tel:+48${contact.mobile}`}>Tel. {contact.mobile}</a>
                </li>
                <li>
                  <a href={`mailto:${contact.email}`}>{contact.email}</a>
                </li>
              </ul>
            </div>
            <div className="mt-8">
              <div className="mb-3 flex items-center justify-start">
                <FontAwesomeIcon icon={faClock} className="mr-3 text-4xl" />
                <Headline
                  level={2}
                  title="Godziny odwiedzin"
                  bgClassName=""
                  paddingClassName=""
                />
              </div>
              <div className="py-3">
                <div>{contact.week_days}</div>
                <div>{contact.week_h}</div>
              </div>
              <div className="py-3">
                <div>{contact.weekend}</div>
                <div>{contact.weekend_h}</div>
              </div>
            </div>
          </div>
        </SectionContainer>
      </div>
    </Layout>
  );
};

export default Contact;

export const query = graphql`
  query Contact {
    directus {
      contact_page {
        email
        mobile
        phone
        week_days
        week_h
        weekend
        weekend_h
      }
      hero_section {
        hero_image {
          id
          imageFile {
            previewImage: childImageSharp {
              resize(
                width: 1200
                height: 630
                quality: 85
                cropFocus: ATTENTION
                fit: COVER
              ) {
                src
              }
            }
          }
        }
      }
    }
  }
`;
